import { useState } from 'react';

import { tw } from '~/libs/tailwind/tw';
import { Button } from './buttons/button';

// ? later https://www.cookiebot.com/ with GTM
// ?    or https://cookieconsent.orestbida.com/

export const CookieConsent = () => {
  const [isCookieShown, setCookieShown] = useState(
    localStorage.getItem('cookieShown') === 'true'
  );

  if (isCookieShown) {
    return null;
  }

  const style = cookieConsentStyle();

  return (
    <div role="alert" aria-live="polite" className={style.container()}>
      <span className={style.message()}>
        This site uses cookies for analytics
      </span>
      <Button
        className={style.agreeBtn()}
        onClick={() => {
          localStorage.setItem('cookieShown', 'true');
          setCookieShown(true);
        }}
      >
        Got it
      </Button>
    </div>
  );
};

const cookieConsentStyle = tw({
  slots: {
    container: [
      'px-8 py-4',
      'fixed right-2 bottom-2 z-50 ml-2',
      'bg-white border-2 border-neutral-100',
      'flex flex-row flex-wrap gap-y-3 gap-x-8 items-center justify-center',
    ],
    message: 'text-center text-balance py-2',
    agreeBtn: 'px-5 py-4 h-auto grow',
  },
});
