import { type VariantProps } from 'tailwind-variants';
import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
} from 'react';

import { buttonStyle } from '../buttonStyle';

const Button = forwardRef<
  ElementRef<'button'>,
  ComponentPropsWithoutRef<'button'> & VariantProps<typeof buttonStyle>
>(function Button(
  {
    className,
    bordered,
    transparent,
    asIcon,
    inverted,
    fullWidth,
    disabled,
    ...props
  },
  ref
) {
  return (
    <button
      type="button"
      {...props}
      ref={ref}
      className={buttonStyle({
        className,
        bordered,
        transparent,
        asIcon,
        inverted,
        fullWidth,
        disabled,
      })}
      disabled={disabled}
    />
  );
});

export { Button };
