import { type ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { ParallaxProvider } from 'react-scroll-parallax';

import './src/styles/fonts.css';
import './src/styles/index.css';
import { CookieConsent } from '~/components/cookieConsent';

export const wrapRootElement = ({ element }: { element: ReactNode }) => (
  <ParallaxProvider>{element}</ParallaxProvider>
);

export const wrapPageElement = ({ element }: { element: ReactNode }) => (
  <>
    {element}
    <CookieConsent />
  </>
);

export const replaceHydrateFunction = () => {
  return (element: ReactNode, container: ReactDOM.Container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
