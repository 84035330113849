import { createTV } from 'tailwind-variants';

const tw = createTV({
  twMerge: true,
  twMergeConfig: {
    extend: {
      classGroups: {
        text: [
          'text-display-1',
          'text-display-2',
          'text-display-3',

          'text-headline-1',
          'text-headline-2',
          'text-headline-3',
          'text-headline-4',

          'text-title-1',
          'text-title-2',
          'text-title-3',
          'text-title-4',

          'text-body-1',
          'text-body-2',
          'text-body-3',
          'text-body-4',

          'text-base',
        ],
      },
    },
  },
});

export { tw };
